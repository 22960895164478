import Typed from 'typed.js';
import Siema from 'siema';
import tippy from 'tippy.js';

function fadeOut(el) {
  el.style.opacity = 1; // eslint-disable-line no-param-reassign

  (function fade() {
    // eslint-disable-next-line no-cond-assign, no-param-reassign
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = 'none'; // eslint-disable-line no-param-reassign
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

function fadeIn(el, display) {
  el.style.opacity = 0; // eslint-disable-line no-param-reassign
  el.style.display = display || 'block'; // eslint-disable-line no-param-reassign

  (function fade() {
    let val = parseFloat(el.style.opacity);
    // eslint-disable-next-line no-cond-assign, no-param-reassign
    if (!((val += 0.1) > 1)) {
      el.style.opacity = val; // eslint-disable-line no-param-reassign
      requestAnimationFrame(fade);
    }
  })();
}

function stripeResponseHandler(form, response) {
  const hiddenInput = document.createElement('input');
  const encodedResponse = window.btoa(
    unescape(encodeURIComponent(JSON.stringify(response)))
  );

  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripe_response');
  hiddenInput.setAttribute('value', encodedResponse);
  form.appendChild(hiddenInput);
  form.submit();
}

function enableInputs(form, enabled = true) {
  Array.prototype.forEach.call(
    form.querySelectorAll("input[type='text'], input[type='submit']"),
    input => {
      input.disabled = !enabled; // eslint-disable-line no-param-reassign
    }
  );
}

function setActiveCarouselSlide(carousel, controls) {
  const {currentSlide} = carousel;

  Array.prototype.forEach.call(controls, item => {
    if (parseInt(item.dataset.index, 10) === currentSlide) {
      item.classList.add('active');
    } else {
      item.classList.remove('active');
    }
  });
}

// Dropdown
let currentDropdown = false;

function toggleDropdown(e) {
  e.preventDefault();
  e.stopPropagation();

  const el = this;
  let menu = el.nextSibling;

  while (menu && menu.nodeType !== 1) {
    menu = menu.nextSibling;
  }
  if (!menu) {
    return;
  }

  if (menu.style.display === 'block') {
    // visible
    fadeOut(menu);
    currentDropdown = false;
  } else {
    // hidden
    fadeIn(menu);
    if (currentDropdown && currentDropdown !== menu) {
      // hide others
      currentDropdown.style.display = 'none';
    }
    currentDropdown = menu;
  }
}

document.querySelectorAll('.dropdown-trigger').forEach(el => {
  el.addEventListener('click', toggleDropdown, false);
});

window.onclick = () => {
  if (currentDropdown) {
    fadeOut(currentDropdown);
  }
};

// Flash messages
const flash = document.querySelectorAll('.flash');
Array.prototype.forEach.call(flash, flashItem => {
  const flashClose = flashItem.querySelector('.close');
  flashClose.addEventListener('click', e => {
    e.preventDefault();
    fadeOut(e.target.parentNode);
  });
});

if (document.getElementById('site-index')) {
  // FIXME: Does not work correctly
  // // Scrollspy
  // var section = document.querySelectorAll('section');
  // var nav = document.querySelector('.nav');
  // var sections = {};
  // var i = 0;

  // Array.prototype.forEach.call(section, function(e) {
  //   sections[e.id] = e.offsetTop;
  // });

  // window.onscroll = function() {
  //   var scrollPosition =
  //     document.documentElement.scrollTop || document.body.scrollTop;

  //   for (i in sections) {
  //     if (sections[i] && sections[i] <= scrollPosition) {
  //       var activeLink = nav.querySelector('.active');
  //       var currentLink = nav.querySelector('a[href*=' + i + ']');

  //       activeLink && activeLink.setAttribute('class', ' ');
  //       currentLink && currentLink.setAttribute('class', 'active');
  //     }
  //   }
  // };

  // Mobile nav
  const navContainer = document.querySelector('nav');
  const nav = document.querySelector('ul.nav');
  const navToggle = document.querySelector('nav .menu');
  const navLinks = document.querySelectorAll('ul.nav a');

  if (navToggle) {
    navToggle.addEventListener(
      'click',
      e => {
        nav.classList.toggle('open');
        e.preventDefault();
      },
      false
    );
  }

  document.addEventListener('click', event => {
    const isClickInside = navContainer.contains(event.target);
    if (!isClickInside && nav.classList.contains('open')) {
      nav.classList.remove('open');
    }
  });

  Array.prototype.forEach.call(navLinks, item => {
    item.addEventListener('click', () => {
      nav.classList.remove('open');
    });
  });

  // Features carousel
  const featuresControls = document.querySelectorAll('.features-control');

  const featuresCarousel = new Siema({
    selector: '.features',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    multipleDrag: true,
    threshold: 20,
    loop: false,
    rtl: false,
    onInit: () => {},
    onChange() {
      setActiveCarouselSlide(this, featuresControls);
    },
  });

  Array.prototype.forEach.call(featuresControls, item => {
    item.addEventListener('click', e => {
      e.preventDefault();
      featuresCarousel.goTo(e.target.dataset.index);
    });
  });

  // Typing effect
  // eslint-disable-next-line no-unused-vars
  const typed = new Typed('#typed-slack-command', {
    strings: [
      '/deploy lock',
      '/deploy lock',
      '/deploy where',
      '/deploy latest',
      '/deploy queue',
      '/deploy',
    ],
    showCursor: true,
    typeSpeed: 50,
    backSpeed: 40,
    startDelay: 3000,
    backDelay: 3000,
    smartBackspace: false,
  });

  // FAQ expand
  const faq = document.getElementById('faq');
  Array.prototype.forEach.call(faq.querySelectorAll('h3'), item => {
    item.addEventListener('click', e => {
      e.target.parentNode.classList.toggle('active');
    });
  });
}

if (document.getElementById('accounts-edit')) {
  // Stripe Card
  const form = document.getElementById('stripe-card');
  const errorElement = document.getElementById('card-errors');
  // Stripe is included in global scope
  // eslint-disable-next-line no-undef
  const stripe = Stripe(form.dataset.key);

  const elements = stripe.elements({
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
    locale: 'en',
  });

  const card = elements.create('card', {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#9EA0A5',
        color: '#3E3F42',
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#9EA0A5',
        },
      },
      invalid: {
        iconColor: '#F55C51',
        color: '#F55C51',
      },
    },
  });

  card.addEventListener('change', event => {
    if (event.error) {
      errorElement.textContent = event.error.message;
    } else {
      errorElement.textContent = '';
    }
  });

  card.mount('#card-element');

  form.addEventListener('submit', event => {
    event.preventDefault();

    const name = form.querySelector('#card_name');

    if (name.value.length < 3) {
      errorElement.textContent = "Your cardholder's name is incomplete.";
      enableInputs(form);
      return false;
    }

    const additionalData = {
      name: name ? name.value : undefined,
    };

    enableInputs(form, false);

    stripe.createToken(card, additionalData).then(result => {
      if (result.error) {
        errorElement.textContent = result.error.message;
        enableInputs(form);
      } else {
        stripeResponseHandler(form, result.token);
      }
    });

    return true;
  });
}

// Tooltips
Array.prototype.forEach.call(document.querySelectorAll('.tooltip'), item => {
  const {title} = item.dataset;
  if (title.length > 0) {
    tippy(item, {
      content: title,
      arrow: true,
      arrowType: 'small',
      duration: [275, 250],
      animation: 'shift-away',
      inertia: true,
      theme: 'default',
    });
  }
});
